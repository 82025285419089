import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Circle from "../shared/circle/circle";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { GridList, GridListTile } from "@material-ui/core";
import axios from "axios";
import { sizing } from "@material-ui/system";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    height: 600,
    margin: "0 auto !important",
    maxWidth: "80%",
  },
  noDisplay: {
    display: "none",
  },
  loading: {
    textAlign: "center",
  },
  error: {
    color: "red",
  },
  imageGrid: {
    maxWidth: "1200px",
    margin: "0 auto",
  },
}));

const Gallery = (props) => {
  const [images, setImages] = useState("");
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const bucketUrl = process.env.REACT_APP_AMAZON_BUCKET_URL;

  useEffect(() => {
    const fetchImages = async () => {
      setIsError(false);
      setIsLoading(true);

      try {
        const result = await axios(imageUrl);
        setImages(result.data);
      } catch (error) {
        setIsError(true);
      }

      setIsLoading(false);
    };

    fetchImages();
  }, [imageUrl]);

  const classes = useStyles();

  const [slides, setSlides] = useState({
    isOpen: false,
    photoIndex: 0,
  });

  const updateSlides = () => {
    setSlides({
      isOpen: !slides.isOpen,
    });
  };

  const displayImages = (images) => {
    let imagesArray = [];
    for (let key in images) {
      imagesArray.push(`${bucketUrl}/${images[key].Key}`);
    }
    const imagesToDisplay = imagesArray.map((image, index) => {
      return (
        <GridListTile key={index} cols={1}>
          <img
            src={image}
            alt=""
            onClick={() => setSlides({ isOpen: true, photoIndex: index })}
          />
        </GridListTile>
      );
    });
    return imagesToDisplay;
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  // Hook
  // const useWindowSize = () => {
  //     const getSize = () => {
  //         return {
  //             width: window.innerWidth
  //         };
  //     }

  //     const [windowSize, setWindowSize] = useState(getSize);

  //     useEffect(() => {
  //         function handleResize() {
  //             setWindowSize(getSize());
  //         }

  //         window.addEventListener('resize', handleResize);
  //         return () => window.removeEventListener('resize', handleResize);
  //     }, []); // Empty array ensures that effect is only run on mount and unmount

  //     return windowSize;
  // }

  // const size = useWindowSize();

  // const getGridListCols = () => {
  // if (isWidthUp('xl', props.width)) {
  //   return 4;
  // }

  // if (isWidthUp('lg', props.width)) {
  //   return 3;
  // }

  // if (isWidthUp('md', props.width)) {
  //   return 2;
  // }

  // return 1;
  //     console.log('getgridlist')
  //   }

  return (
    <React.Fragment>
      <Circle />
      {isError && (
        <div className={classes.error}>
          <h2>
            Something went wrong with loading the images...please try again
            later.
          </h2>
        </div>
      )}

      {isLoading ? (
        <div className={classes.loading}>
          <h1>Loading...</h1>
        </div>
      ) : (
        <div className={classes.imageGrid}>
          <GridList cellHeight={300} className={classes.gridList} cols={3}>
            {displayImages(images)}
          </GridList>
          {slides.isOpen && (
            <Lightbox
              mainSrc={`${bucketUrl}/${images[slides.photoIndex].Key}`}
              nextSrc={
                `${bucketUrl}/` +
                images[(slides.photoIndex + 1) % images.length].Key
              }
              prevSrc={
                `${bucketUrl}/` +
                images[(slides.photoIndex + images.length - 1) % images.length]
                  .Key
              }
              onCloseRequest={() => updateSlides()}
              onMovePrevRequest={() =>
                setSlides({
                  isOpen: true,
                  photoIndex:
                    (slides.photoIndex + images.length - 1) % images.length,
                })
              }
              onMoveNextRequest={() =>
                setSlides({
                  isOpen: true,
                  photoIndex: (slides.photoIndex + 1) % images.length,
                })
              }
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default Gallery;
