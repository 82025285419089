import React from "react";
import "./footer.scss";

const Footer = () => {
    return(
        <footer className="footer">
            <p>Henrico County Economic Development Authority  •  4300 E. Parham Road  •  Henrico, VA 23228
            USA  •  804.501.7654<br />© 2020 Henrico County Economic Development Authority  •  <a href="https://www.henrico.com/privacy">Privacy Statement</a>
            <br/>
            To learn more about Henrico EDA, visit <a href="https://www.henrico.com">henrico.com</a></p>
        </footer>
    );
};

export default Footer;