import React, { useState } from "react";
import "./navigation.scss";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Link} from 'react-router-dom';

const useStyles = makeStyles({
    root: {
      flexGrow: 1,
    },
  });

const Navigation = () => {
    let pageLocation= window.location.pathname;
    let pageNum;

    //console.log(pageLocation);

    const resolvePage = (location) => {
      switch(location){
        case '/':
          pageNum=0;
          break;
        case '/register':
          pageNum=1;
          break;
        case '/contact':
          pageNum=2;
          break;
        default:
          pageNum=0;
      }
      
    }

    resolvePage(pageLocation);

    const classes = useStyles();
    const [value, setValue] = useState(pageNum);
      
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    
    return(
        <Paper className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="HOME" component={Link} to="/" />
          <Tab label="REGISTRATION" component={Link} to="/register" />
          <Tab label="GALLERY" component={Link} to="/gallery" />
          <Tab label="CONTACT US" component={Link} to="/contact"/>
        </Tabs>
      </Paper>
    );
};

export default Navigation;