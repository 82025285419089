import React, {  } from "react";
import "./circle.scss";
import HenricoCircle from "./images/henrico-circle.png";
import Grid from '@material-ui/core/Grid';

const Circle = () => {
    return(
        <div className="content-circle-logo">
            <Grid container direction="row" justify="center">
                <img src={HenricoCircle} className="rounded mx-auto d-block logo"/>
            </Grid>
        </div>
    );
};

export default Circle;