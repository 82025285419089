import React, { useEffect, useState } from "react";
import "./landing.scss";
import { Link } from "react-router-dom";
import HenricoDetail from "./images/henrico-detail.png";
import HenricoCircleAlt from "./images/Henrico_Circle_Alt.png";
//import Link from '@material-ui/core/Link';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import ImageSlider from "../shared/slider/slider";
import axios from "axios";

const LandingData = () => {
  const useStyles = makeStyles(() => ({
    circleText: {
      backgroundColor: "#f0f0f0",
      paddingTop: 25,
      paddingBottom: 15,
    },
    circleContainer: {
      paddingTop: "1.5rem",
      paddingBottom: "1.5rem",
    },
    smallText: {
      fontSize: 18,
      color: "#676776",
    },
    largeText: {
      fontSize: 28,
      color: "#0f75cc",
      fontWeight: 900,
    },
    mediumText: {
      fontSize: 22,
      color: "#0f75cc",
      fontWeight: 900,
    },
    smallTextBold: {
      fontSize: 18,
      color: "#676776",
      fontWeight: 900,
    },
    textContainerCenter: {
      paddingLeft: "3.5rem",
      paddingRight: "3.5rem",
    },
    carouselContainerCenter: {
      paddingTop: "1rem",
      paddingBottom: "1rem",
      paddingLeft: "3.5rem",
      paddingRight: "3.5rem",
    },
    appendGrey: {
      backgroundColor: "#f0f0f0",
      height: "3.5rem",
    },
  }));

  const classes = useStyles();
  const [pageData, setPageData] = useState({});

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_UPDATE_API_URL}/hcircle-home`)
      .then((res) => {
        const data = res.data.fields[0];
        const { featured_industry, date, event_details_description, location } = data;
        setPageData({
          featured_industry,
          date,
          event_details_description,
          location
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <React.Fragment>
      <div className={classes.circleContainer}>
        <Grid container direction="row" justify="center">
          <img
            src={HenricoCircleAlt}
            className="rounded mx-auto d-block logo"
          />
        </Grid>
      </div>
      <div className="container">
        <Grid
          container
          direction="row"
          justify="center"
          className={classes.textContainerCenter}
        >
          <Grid container justify="center" className={classes.smallText}>
           {/* <p>This premier networking event sponsored by the Henrico EDA will allow attendees to connect with peers, share industry insights, and provide feedback on new developments and initiatives in Henrico County.</p>
            <p>We invite you to come out, meet each other, and engage with peers in your Henrico Circle. No registration fee.</p>*/}
             <p
              dangerouslySetInnerHTML={{
                __html: pageData.event_details_description,
              }}
            /> 
          </Grid>
          {/*<Grid container justify="center" className={classes.smallText} paddingBottom="0">
            <p><strong>Featured Industry:</strong> Printing & Packaging</p>
          </Grid>
          <Grid container justify="center" className={classes.smallText}>
            <p><strong>When:</strong> August 16, 2023 (4:00PM-6:00PM)</p>
          </Grid>
          <Grid container justify="center" className={classes.smallText}>
            <p><strong>Where:</strong> Henrico Theatre, 305 East Nine Mile Rd., Highland Springs, VA 23076</p>
          </Grid>
          <Grid container justify="center" className={classes.largeText}>
            <p>
              <Link to="/register" color="inherit">
                PLEASE CLICK HERE TO REGISTER
              </Link>
            </p>
          </Grid>*/}
          <Grid container justify="center" className={classes.smallText}>
            <p> <a href="https://www.henricocircle.com/gallery">Click to view photo gallery from past events.</a><br/><br/></p>    
          </Grid>
        </Grid>
        {/* <Grid
          container
          direction="row"
          justify="center"
          className={classes.textContainerCenter}
        >
          <Grid container justify="center" className={classes.smallText}>
            <span>This quarter's event features the</span>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          className={classes.carouselContainerCenter}
        >
          <Grid container justify="center" className={classes.largeText}>
            <p>&nbsp;Finance and Insurance Industry</p>
          </Grid>
        </Grid> */}
      </div>

      {/* <ImageSlider /> */}

      <div className="container">
        {/* <Grid
          container
          direction="row"
          justify="center"
          className={classes.carouselContainerCenter}
        >
          <Grid container justify="center" className={classes.largeText}>
            <span
              dangerouslySetInnerHTML={{
                __html: pageData.date,
              }}
            />
          </Grid>
        </Grid> */}
        {/* <Grid container direction="row" justify="center">
          <Grid container justify="center" className={classes.smallTextBold}>
            <p class="pEventDetails">
                <strong>WHEN</strong>:
                <br />
                March 16, 2023 | 7:30-9:00 AM
            </p>
          </Grid>
          <Grid container justify="center" className={classes.smallTextBold}>
            <p class="pEventDetails">
                <strong>WHERE</strong>: &nbsp;
                <br />
                <strong>Allianz Partners</strong>
                <br />
                9950 Mayland Dr
                <br />
                Henrico VA, 23233&nbsp;
            </p>
            <p><br /></p>
          </Grid>
        </Grid> */}

        {/* <Grid
          container
          direction="row"
          justify="center"
          className={classes.textContainerCenter}
        >
          <Grid container justify="center" className={classes.smallText}>
            <p>
            We invite you to come out and engage with peers in your Henrico Circle. Breakfast provided. Free to register.
            </p>
          </Grid>
        </Grid> */}

        <Grid
          container
          direction="row"
          justify="center"
          className={classes.textContainerCenter}
        >
        </Grid>
        <Grid container direction="row" justify="center">
          <Grid container justify="center"></Grid>
        </Grid>
      </div>
      <Grid
        container
        direction="row"
        justify="center"
        className={classes.circleText}
      >
        <img src={HenricoDetail} className="rounded mx-auto d-block logo" />
      </Grid>
      {/* <Grid container direction="row" justify="center" className={classes.circleText}>
            <Grid container justify="center" className={classes.mediumText}>
               <p>SIGN ME UP FOR UPDATES FROM HENRICO CIRCLE!</p>
            </Grid>
        </Grid> */}
        <Grid container direction="row" className={classes.appendGrey}></Grid>
    </React.Fragment>
  );
};

export default LandingData;
