import React from "react";
import "./header.scss";
import edaLogo from "./images/henrico-eda-logo.png";
import Grid from '@material-ui/core/Grid';

const Header = () => {
    return(
        <div className="heading">
            <Grid container direction="row" justify="center">
                <a href="https://www.henrico.com">
                    <img src={edaLogo} className="rounded mx-auto d-block logo" alt="..." />
                </a>
            </Grid>
        </div>
    );

};

export default Header;