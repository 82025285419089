import React, { useState } from "react";
import "./contact.scss";
import emailjs from 'emailjs-com';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Circle from "../shared/circle/circle";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


const Contact = () => {
    
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [signup, setSignup] = useState("false");
    const [message, setMessage] = useState("");
    const [fnameError, setFnameError] = useState(false);
    const [lnameError, setLnameError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [open, setOpen] = useState(false);

    const validateFnameField = (event) =>{ 
        if (event.value === "") {
          setFnameError(true);
        }
        else{
          setFnameError(false);
        }
    };

    const validateLnameField = (event) =>{
      if (event.value === "") {
        setLnameError(true);
      }
      else{
        setLnameError(false);
      }
    };

    const validatePhoneField = (event) =>{
        if (event.value === "") {
          setPhoneError(true);
        }
        else{
          setPhoneError(false);
        }
    };

    const validateEmailField = (event) =>{
      if (event.value === "") {
        setEmailError(true);
      }
      else{
        setEmailError(false);
      }
    };
    
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
    };

    const useStyles = makeStyles(theme => ({
        paper: {
          marginTop: theme.spacing(8),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          border: 1,
          borderColor: 'primary.main',
          borderRadius: 3,
          paddingLeft: 25,
          paddingRight: 25,
        },
        avatar: {
          margin: theme.spacing(1),
          backgroundColor: theme.palette.secondary.main,
        },
        form: {
          width: '100%', // Fix IE 11 issue.
          marginTop: theme.spacing(3),
        },
        input: {
          paddingBottom: 30, 
        },
        button: {
          backgroundColor: theme.palette.success.main,
          margin: theme.spacing(3, 0, 2),
          alignSelf: 'center',
          color: '#FFFFFF',
        },
        smallText: {
            fontSize: 18,
            color: "#676776",
        },
        largeText: {
            fontSize: 28,
            color: "#0f75cc",
            fontWeight: 900,
        },
        smallTextBold: {
            fontSize: 18,
            color: "#676776",
            fontWeight: 900,
        },
        textContainerCenter: {
            paddingLeft: "2.25rem",
            paddingTop: "1.5rem",
            paddingRight: "2.25rem",
        },
        buttonPadding: {
            paddingBottom: "2rem",
        },

      }));

      const classes = useStyles();

      function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
      }

      function sendEmail(e) {
        if(fname === "" || lname === "" || phone === "" || email === ""){
            console.log("error")
            console.log(fname);
        } else {
        
        e.preventDefault();
        
        emailjs.sendForm('default_service', 'eda_conatact_us', e.target, 'user_dXpAOOtFEW0NtZBmi9jbL')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });

        setOpen(true);
      }
    }

    return(
        <React.Fragment>
                <Circle />
                <Container component="main" maxWidth="md" border={1}>
                <CssBaseline />
                <Box border={1}>
                <div className="container" className={classes.textContainerCenter}>
                    <Grid container direction="row">
                        <Grid container className={classes.largeText}>
                           CONTACT US
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid container className={classes.smallText}>
                            Have questions or want more information? Contact us directly or fill out the form below.
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid container className={classes.smallTextBold}>
                            <p> <br/>
                             </p>
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid container className={classes.smallText}>
                            <p>Henrico County Economic Development Authority<br/>
                            4300 E. Parham Road, Henrico, Virginia 23228<br/>
                            Direct: 804-501-7522 | Mobile: 804-212-4663<br/>
                            ebonie@henrico.com | www.henrico.com |</p>
                        </Grid>
                    </Grid>
                </div>    
                <div className={classes.paper}>
                    <form className={classes.form} noValidate id="my-form" onSubmit={sendEmail}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} lg={6}>
                        <TextField
                            autoComplete="fname"
                            name="firstName"
                            variant="filled"
                            required
                            fullWidth
                            error={fnameError}
                            id="firstName"
                            label="First Name"
                            value={fname}
                            onChange={e => setFname(e.target.value)}
                            onBlur={e => validateFnameField(e.target)}
                            autoFocus
                        />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                        <TextField
                            variant="filled"
                            required
                            fullWidth
                            value={lname}
                            error={lnameError}
                            id="lastName"
                            label="Last Name"
                            name="lastName"
                            autoComplete="lname"
                            onChange={e => setLname(e.target.value)}
                            onBlur={e => validateLnameField(e.target)}
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <TextField
                            variant="filled"
                            required={true}
                            error={emailError}
                            fullWidth
                            id="email"
                            value={email}
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            onChange={e => setEmail(e.target.value)}
                            onBlur={e => validateEmailField(e.target)}
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <TextField
                            variant="filled"
                            required
                            fullWidth
                            error={phoneError}
                            name="phno"
                            value={phone}
                            label="Phone Number:"
                            type="phno"
                            id="phno"
                            onChange={e => setPhone(e.target.value)}
                            onBlur={e => validatePhoneField(e.target)}
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <TextField
                            variant="filled"
                            rowsmin={3}
                            multiline
                            fullWidth
                            value={message}
                            name="message"
                            label="Message"
                            type="message"
                            id="message"
                            onChange={e => setMessage(e.target.value)}
                            className={classes.input}
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <Checkbox
                                id="emailAdd"
                                name="emailAdd"
                                color="default"
                                label="Sign me up for current event updates from Henrico Circle!"
                                onChange={e => (setSignup(e.target.checked))}
                                value={signup}
                        />
                        Sign me up for current event updates from Henrico Circle!
                        </Grid>
                        <Grid container direction="row" justify="center" className={classes.buttonPadding}>
                        <Button
                            type="submit"   
                            variant="contained"
                            color="success"
                            justify="center"
                            onSubmit={sendEmail}
                            className={classes.button}
                        >
                            SUBMIT
                        </Button>
                        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                            <Alert onClose={handleClose} severity="success">
                            Your message has been sent!
                            </Alert>
                        </Snackbar>
                    </Grid>
                    </Grid>
                    </form>
                </div>
                </Box>
                </Container>
              
        </React.Fragment>
    );
};

export default Contact;