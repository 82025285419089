import React, { useState, useEffect } from "react";
import axios from "axios";
import "./register.scss";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Circle from "../shared/circle/circle";
import Modal from "@material-ui/core/Modal";
import MuiAlert from "@material-ui/lab/Alert";
import EDALogo from "./images/henrico-eda-logo.png";
import InputMask from "react-input-mask";

function useAsyncEndpoint(fn) {
  const [res, setRes] = useState({
    data: null,
    complete: false,
    pending: false,
    error: false,
  });
  const [req, setReq] = useState();

  useEffect(() => {
    if (!req) return;
    setRes({
      data: null,
      pending: true,
      error: false,
      complete: false,
    });
    axios(req)
      .then((res) =>
        setRes({
          data: res.data,
          pending: false,
          error: false,
          complete: true,
        })
      )
      .catch(() =>
        setRes({
          data: null,
          pending: false,
          error: true,
          complete: true,
        })
      );
  }, [req]);
  return [res, (...args) => setReq(fn(...args))];
}

const registerApi = "https://hcircle20.herokuapp.com/rsvp";

function postRegisterEndpoint() {
  /* eslint-disable react-hooks/rules-of-hooks */
  return useAsyncEndpoint((data) => ({
    url: registerApi,
    method: "POST",
    data,
  }));
}

const Register = () => {
  const [firstName, setFname] = useState("");
  const [lastName, setLname] = useState("");
  const [role, setRole] = useState("supplier");
  const [title, setTitle] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhone] = useState("");
  const [physicalAddress, setAddress] = useState("");
  const [fnameError, setFnameError] = useState(false);
  const [lnameError, setLnameError] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [companyError, setCompanyError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [buttonDisable, setButtonDisable] = React.useState(false);

  const [, postNewRegistration] = postRegisterEndpoint();

  function createRegistration() {
    if (
      firstName === "" ||
      lastName === "" ||
      role === "" ||
      title === "" ||
      company === "" ||
      email === ""
    ) {
      console.log("error");
    } else {
      postNewRegistration({
        firstName,
        lastName,
        role,
        title,
        company,
        email,
        phoneNumber,
        physicalAddress
      });

      setFname("");
      setLname("");
      setRole("");
      setTitle("");
      setCompany("");
      setEmail("");
      setPhone("");
      setAddress("");

      setButtonDisable(true);
      setOpen(true);
    }
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setButtonDisable(false);
    setOpen(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const validateFnameField = (event) => {
    if (event.value === "") {
      setFnameError(true);
    } else {
      setFnameError(false);
    }
  };

  const validateLnameField = (event) => {
    if (event.value === "") {
      setLnameError(true);
    } else {
      setLnameError(false);
    }
  };

  const validateTitleField = (event) => {
    if (event.value === "") {
      setTitleError(true);
    } else {
      setTitleError(false);
    }
  };

  const validateCompanyField = (event) => {
    if (event.value === "") {
      setCompanyError(true);
    } else {
      setCompanyError(false);
    }
  };

  const validateEmailField = (event) => {
    if (event.value === "") {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const [pageData, setPageData] = useState({});

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_UPDATE_API_URL}/hcircle-registration`)
      .then((res) => {
        const data = res.data.fields[0];
        const { featured_industry_title, featured_industry_copy } = data;

        setPageData({
          featured_industry_title,
          featured_industry_copy,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }, [pageData]);

  const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      border: 1,
      borderColor: "primary.main",
      borderRadius: 3,
      paddingLeft: 25,
      paddingRight: 25,
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      backgroundColor: theme.palette.success.main,
      margin: theme.spacing(3, 0, 2),
      alignSelf: "center",
      color: "#FFFFFF",
    },
    input: {
      paddingBottom: 30,
    },
    largeText: {
      fontSize: 28,
      color: "#0f75cc",
      fontWeight: 900,
      paddingBottom: 30,
      paddingTop: 10,
    },
    smallTextBold: {
      fontSize: 18,
      color: "#676776",
      fontWeight: 900,
    },
    smallText: {
      fontSize: 18,
      color: "#676776",
      paddingBottom: 0,
    },
    textContainerCenter: {
      paddingLeft: "5.5rem",
      paddingRight: "3.5rem",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    modalPaper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      // padding: theme.spacing(2, 4, 3),
    },
    modalHeadline: {
      backgroundColor: "#0b3788",
      paddingTop: "1rem",
      paddingBottom: "1rem",
    },
    modalText: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
    modalButton: {
      color: "#FFFFFF",
      backgroundColor: "#0b3788",
    },
    modalButtonPadding: {
      paddingBottom: "1.5rem",
    },
  }));

  const classes = useStyles();

  return (
    <React.Fragment>
      <Circle />
      <div className="container">
        <Grid container direction="row" justify="center">
          <Grid container justify="center" className={classes.smallText}>
            {/* This event's featured industry */}

            <p
              dangerouslySetInnerHTML={{
                __html: pageData.featured_industry_title,
              }}
            />
            <br />
          </Grid>
        </Grid>
        {/*<Grid container direction="row" justify="center">
          <Grid container justify="center" className={classes.smallText} paddingBottom="0">
            <p><strong>Featured Industry:</strong> Printing & Packaging</p>
          </Grid>
          <Grid container justify="center" className={classes.smallText}>
            <p><strong>When:</strong> August 16, 2023 (4:00PM-6:00PM)</p>
          </Grid>
          <Grid container justify="center" className={classes.smallText}>
            <p><strong>Where:</strong> Henrico Theatre, 305 East Nine Mile Rd., Highland Springs, VA 23076</p>
          </Grid>*/}
          {/* <Grid container justify="center" className={classes.smallTextBold}>
            <p>
                <strong>WHEN</strong>:
                <br />
                March 16, 2023 | 7:30-9:00 AM
            </p>
          </Grid>
          <Grid container justify="center" className={classes.smallTextBold}>
            <p class="pEventDetails">
                <strong>WHERE</strong>: &nbsp;
                <br />
                <strong>Allianz Partners</strong>
                <br />
                9950 Mayland Dr
                <br />
                Henrico VA, 23233&nbsp;
            </p>
            <p><br /></p>
          </Grid> 
        </Grid>*/}
        <Grid
          container
          direction="row"
          justify="center"
          className={classes.textContainerCenter}
        >
          <Grid container justify="center" className={classes.largeText}>
            {/* PROFESSIONAL AND CREATIVE SERVICES */}
          </Grid>
        </Grid>
      </div>

      {/* <Container component="main" maxWidth="md" border={1}>
        <CssBaseline />
        <Box border={1}>
          <div className={classes.paper}>
            <form className={classes.form} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} lg={12}>
                  <TextField
                    autoComplete="fname"
                    name="firstName"
                    variant="standard"
                    required
                    helperText=""
                    error={fnameError}
                    fullWidth
                    id="fname"
                    value={firstName}
                    label="First Name (to be shown on your nametag):"
                    autoFocus
                    onChange={(e) => setFname(e.target.value)}
                    onBlur={(e) => validateFnameField(e.target)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={12}>
                  <TextField
                    variant="standard"
                    required
                    fullWidth
                    value={lastName}
                    error={lnameError}
                    id="lname"
                    label="Last Name:"
                    name="lastName"
                    autoComplete="lname"
                    onChange={(e) => setLname(e.target.value)}
                    onBlur={(e) => validateLnameField(e.target)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <p>Check the box next to your role:*</p>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <RadioGroup
                      aria-label="role"
                      name="role"
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                    >
                      <FormControlLabel
                        value="supplier"
                        control={<Radio color="primary" />}
                        label={
                          <div>
                            <Typography variant="title">
                              Supplier &nbsp;
                            </Typography>
                            <Typography variant="caption">
                              (I sell to the industry)
                            </Typography>
                          </div>
                        }
                      />
                      <FormControlLabel
                        value="practitioner"
                        control={<Radio color="primary" />}
                        label={
                          <div>
                            <Typography variant="title">
                              Practitioner &nbsp;
                            </Typography>
                            <Typography variant="caption">
                              (I work in this industry)
                            </Typography>
                          </div>
                        }
                      />
                      <FormControlLabel
                        value="customer"
                        control={<Radio color="primary" />}
                        label={
                          <div>
                            <Typography variant="title">
                              Customer &nbsp;
                            </Typography>
                            <Typography variant="caption">
                              (I buy from this industry)
                            </Typography>
                          </div>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="standard"
                    required
                    fullWidth
                    value={title}
                    error={titleError}
                    id="title"
                    label="Title:"
                    name="title"
                    autoComplete="title"
                    onChange={(e) => setTitle(e.target.value)}
                    onBlur={(e) => validateTitleField(e.target)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="standard"
                    required
                    fullWidth
                    value={company}
                    error={companyError}
                    id="company"
                    label="Company:"
                    name="company"
                    autoComplete="company"
                    onChange={(e) => setCompany(e.target.value)}
                    onBlur={(e) => validateCompanyField(e.target)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="standard"
                    required
                    fullWidth
                    value={email}
                    error={emailError}
                    id="email"
                    label="Email Address:"
                    name="email"
                    autoComplete="email"
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={(e) => validateEmailField(e.target)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputMask
                    mask="(1)999 999 9999"
                    value={phoneNumber}
                    onChange={(e) => setPhone(e.target.value)}
                    className={classes.input}
                  >
                    {() => (
                      <TextField
                        variant="standard"
                        fullWidth
                        name="phno"
                        label="Phone Number:"
                        type="phno"
                        id="phno"
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="standard"
                    fullWidth
                    value={physicalAddress}
                    name="address"
                    label="Physical Address:"
                    type="address"
                    id="address"
                    className={classes.input}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </Grid>
              </Grid>
            </form>
          </div>
        </Box>
        <Grid container direction="row" justify="center">
          <Button
            type="submit"
            variant="contained"
            color="success"
            justify="center"
            disabled={buttonDisable}
            className={classes.submit}
            onClick={createRegistration}
          >
            SUBMIT
          </Button>
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={open}
            onClose={handleClose}
            className={classes.modal}
          >
            <div className={classes.modalPaper}>
              <Grid
                container
                direction="row"
                justify="center"
                className={classes.modalHeadline}
              >
                <img src={EDALogo} />
              </Grid>
              <Grid
                container
                direction="row"
                className={classes.modalText}
                justify="center"
              >
                <h2 id="simple-modal-title">
                  Henrico Circle Event Registration
                </h2>
              </Grid>
              <Grid
                container
                direction="row"
                className={classes.modalText}
                justify="center"
              >
                <p>
                Thank you for registering for the Henrico Circle Event on August 16, 2023. 
                  <br />
                  The event will take place at Henrico Theatre from 4:00-6:00PM.
                  <br />
                  <br />
                  Details will follow via email to the email address you supplied.
                </p>
              </Grid>
              <Grid
                container
                direction="row"
                justify="center"
                className={classes.modalButtonPadding}
              >
                <Button
                  variant="contained"
                  className={classes.modalButton}
                  onClick={handleClose}
                >
                  Close
                </Button>
              </Grid>
            </div>
          </Modal>
        </Grid>
      </Container>*/}
    </React.Fragment>
  );
};

export default Register;
