import React from 'react';
import {Route, Switch} from "react-router-dom";
import Landing from "./landing/landing";
import Social from "./shared/social/social";
import Contact from "./contact/contact";
import Gallery from "./gallery/gallery";
import Header from "./shared/header/header";
import Register from "./register/register";
import Navigation from "./shared/navigation/navigation";

import Footer from "./shared/footer/footer";
import './App.scss';

function App() {
    return (
      <div className="container-fluid main">
        <Header />
        <Navigation />
        <Switch>
          <Route 
            exact path="/" 
            render={(props) => <Landing {...props}  /> }
            />
          <Route 
            path="/register" 
            render={(props) => <Register {...props}  /> }
            />
          <Route path="/gallery" 
            render={(props) => <Gallery {...props}  /> }
            />}
          <Route path="/contact" 
            render={(props) => <Contact {...props} /> }
            />
        </Switch>
        <Social/>
        <Footer />
      </div>
    );
}

export default App;
