import React, {  } from "react";
import "./social.scss";
import Grid from '@material-ui/core/Grid';
import twitter from "./images/twitter-round-logo.png";
import facebook from "./images/facebook.png";
import linkedin from "./images/linkedin.png";
import { makeStyles } from '@material-ui/core/styles';


const Social = () => {
    const useStyles = makeStyles(() => ({
       rowPadding: {
           paddingTop: 35,
       }
      }));

      const classes = useStyles();
    return(
        <div>
            <Grid container direction="row" justify="center" className={classes.rowPadding}>
                <Grid containerjustify="center">
                    <a href="https://twitter.com/henriconow?lang=en" target="_blank" rel="noopener noreferrer" className="neighborPadding">
                        <img src={twitter} style={{height: "40px", width: "40px"}} className="mr-2"/>
                    </a>
                    <a href="https://www.facebook.com/HenricoNOW/" target="_blank" rel="noopener noreferrer" className="neighborPadding">
                        <img src={facebook} style={{height: "40px", width: "40px"}} className="mr-2"/>
                    </a>
                    <a href="https://www.linkedin.com/company/henrico-economic-development-authority/" target="_blank" rel="noopener noreferrer">
                        <img src={linkedin} style={{height: "40px", width: "40px"}} className="mr-2"/>
                    </a>
                </Grid>
            </Grid>
        </div>
    );
};

export default Social;