import React from 'react';
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import './index.scss';
import App from './components/App';
import { Helmet } from "react-helmet";
import * as serviceWorker from './serviceWorker';
import TagManager from 'react-gtm-module';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'materialui-css';

const tagManagerArgs = {
    gtmId: 'GTM-5PSFF3J'
}

TagManager.initialize(tagManagerArgs)

const Title = 'Henrico Circle';

render(
      <BrowserRouter>
        <Helmet>
            <title>{ Title }</title>
        </Helmet>
        <App />
      </BrowserRouter>,
    document.getElementById("root")
  );
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
